<template>
	<S2SForm title="Assign Transactions">
		<v-data-table
			:headers="headers"
			:items="txnToAssign"
			class="elevation-1"
			:server-items-length="totalItems"
			:options.sync="_pagination"
			:loading="status.loading"
			:footer-props="{ 'items-per-page-options': [10, 25, 50] }"
		>
			<template v-slot:item.amount="{ item }">
				{{ formatCurrency(item.amount) }}
			</template>
			<template v-slot:item.timestamp="{ item }">
				{{ formatDate(item.timestamp) }}
			</template>
			<template v-slot:item.assign="{ item }">
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on" @click="onSearch(item)" :class="item.assign"><v-icon>mdi-clipboard-account</v-icon></v-btn>
					</template>
					<span>Search Organisation to Assign</span>
				</v-tooltip>
			</template>
		</v-data-table>

		<v-dialog v-model="dialog" scrollable max-width="500px" transition="dialog-transition" persistent>
			<v-card title="Organisation">
				<v-card-title>Search Organisation to assign this transaction to</v-card-title>
				<v-card-text>
					<v-alert v-if="notFound" dense text type="warning">
						{{ notFound }}
					</v-alert>

					<v-text-field
						name="orgSearch"
						v-model="code"
						label="Search Organisation by Code"
						append-outer-icon="search"
						@click:append-outer="searchOrg(code)"
						@keydown.enter="searchOrg(code)"
					>
					</v-text-field>
					<v-layout v-if="searchedOrg && code">
						<v-flex xs6>
							<v-text-field readonly label="Organisation" :value="`${searchedOrg.name} - ${searchedOrg.code}`"></v-text-field>
						</v-flex>
						<v-flex xs6>
							<v-select
								hide-details
								v-if="searchedOrg"
								:items="bankingMethods"
								item-text="name"
								item-value="id"
								label="Banking Method"
								v-model="bankingMethod"
							>
							</v-select>
						</v-flex>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="cancelAssign()">Cancel</v-btn>
					<v-btn v-show="searchedOrg && code" color="accent" @click="confirmAssignTxnToOrg()">Assign Organisation</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="confirmDialog" scrollable max-width="800px" transition="dialog-transition" persistent>
			<v-card>
				<v-card-title>Confirm selection</v-card-title>
				<v-card-text v-if="searchedOrg">
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">
										TXN ID
									</th>
									<th class="text-left">
										TXN AMOUNT
									</th>
									<th>
										ORGANISATION
									</th>
									<th>
										BANKING METHOD
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{{ selectedTxn.id }}</td>
									<td>{{ formatCurrency(selectedTxn.amount) }}</td>
									<td>{{ searchedOrg.name }} - {{ searchedOrg.code }}</td>
									<td>{{ chosenBankMethod() }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="cancelConfirm()">Back</v-btn>
					<v-btn color="accent" @click="assignTxnToOrg()">Confirm</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { TransactionToAssign } from "@/store/transactions/types";
import { namespace } from "vuex-class";
import { Status, Paging } from "@/store/utils";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "AssignTransactions",

	computed: {
		_pagination: {
			get() {
				return this.$store.state.transactions.pagination;
			},
			set(value) {
				this.$store.dispatch("transactions/paginate", value);
				// page changed get new data
				this.$store.dispatch("transactions/getTransactionsToAssign");
			}
		},
		txnToAssign: function() {
			return this.$store.state.transactions.txnToAssign;
		},
		searchedOrg: function() {
			return this.$store.state.transactions.searchedOrg;
		},
		status: function() {
			return this.$store.state.transactions.status;
		},
		totalItems: function() {
			return this.$store.state.transactions.totalItems;
		}
	},

	data: function() {
		return {
			headers: [
				{ text: "Date", value: "timestamp", sortable: false },
				{ text: "Amount", value: "amount", sortable: false },
				{ text: "Transaction Type", value: "transactionType", sortable: false },
				{ text: "Bank", value: "bank", sortable: false },
				{ text: "Fee", value: "fee", sortable: false },
				{ text: "Proxy Account Number", value: "proxyAccountNumber", sortable: false },
				{ text: "Reference", value: "reference", sortable: false },
				{ text: "Assign", value: "assign", sortable: false }
			],

			dialog: false,
			confirmDialog: false,
			selectedTxn: {},
			code: "",
			notFound: "",
			bankingMethod: "eft",
			bankingMethods: [
				{ id: "cash_deposit", name: "Cash Deposit" },
				{ id: "eft", name: "EFT" }
			]
		};
	},

	methods: {
		formatCurrency(value) {
			return Utils.formatText(value, Utils.TextType.CURRENCY);
		},
		formatDate(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		},
		onSearch(txn) {
			this.dialog = true;
			this.selectedTxn = txn;

			if (/^([A-Za-z0-9]){6}$/.test(txn.reference)) {
				this.code = txn.reference;
			}
		},
		async searchOrg(code) {
			if (code === "" || code == null) return;
			const result = await this.$store.dispatch("transactions/getOrganisationByCode", code);
			if (!result) {
				this.notFound = "Organisation not found!";
			}
		},
		cancelAssign() {
			this.dialog = false;

			// reset values
			this.$store.dispatch("transactions/getTransactionsToAssign");
			this.confirmDialog = false;
			this.code = null;
			this.notFound = null;
			this.bankingMethod = "eft";
		},
		cancelConfirm() {
			this.confirmDialog = false;
			this.dialog = true;
		},
		async confirmAssignTxnToOrg() {
			this.dialog = false;
			this.confirmDialog = true;
		},
		chosenBankMethod() {
			const method = this.bankingMethods.filter(val => {
				return val.id === this.bankingMethod;
			});

			return method[0].name;
		},
		async assignTxnToOrg() {
			await this.$store.dispatch("transactions/setTxnToOrg", {
				orgId: this.searchedOrg.id,
				txnId: this.selectedTxn.id,
				bankingMethodKey: this.bankingMethod
			});

			// reset values
			this.confirmDialog = false;
			this.code = null;
			this.bankingMethod = "eft";
		}
	}
});
</script>
